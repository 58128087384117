<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan" class="range-date">
            <a-form-item label="录单时间">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户业务编号">
              <a-input v-model="queryParam['customer_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam['serial_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报价编号">
              <a-input v-model="queryParam['quota_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报关单号">
              <a-input v-model="queryParam['declare_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合约号">
              <a-input v-model="queryParam['deal_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="主单号">
              <a-input v-model="queryParam['order_num']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="箱号">
              <a-input v-model="queryParam['box_info']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用">
              <a-select
                v-model="queryParam['fee_id']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in feeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam['pay_target']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in payTargetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('basic_audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="auditOpen('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="createPayment('undo_audit')">创建付款单</a-button
      >
      <a-button type="primary" @click="handleExport">导出</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="order_id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="paymentDetail(record)">查看付款单</a>
        </template>
      </span>
      <div slot="feeItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <a-tag :color="feeStatusColor(item)" style="margin-bottom:2px;">
            {{ feeStatusName(item) }}
          </a-tag>
          <span>{{ item.pay_target_name }} </span>
          <span>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
    </s-table>

    <a-modal
      :visible="paymentVisible"
      :confirmLoading="paymentConfirmLoading"
      title="创建付款单"
      :width="720"
      @cancel="paymentVisible = false"
      @ok="paymentSubmit"
    >
      <a-radio-group v-model="checkedPayTarget" @change="changePayTarget" button-style="solid">
        <a-radio-button v-for="item in paymentPayTarget" :value="item.id" :key="item.id">
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
      <div style="padding: 20px">
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChangePayment"
          style="padding-left:10px;padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-checkbox-group v-model="checkedPaymentList" @change="onChangePayment" style="width:100%;">
          <div class="fee-area">
            <a-row :gutter="24" class="fee-list">
              <a-col :span="12" v-for="p in paymentData" :key="p.id">
                <a-checkbox :value="p.id"> {{ p.quota_num }}/{{ p.fee_name }}/{{ p.money }}{{ p.currency_name }} </a-checkbox>
              </a-col>
            </a-row>
          </div>
        </a-checkbox-group>
      </div>
    </a-modal>

    <a-modal
      :visible="auditVisible"
      :confirmLoading="auditConfirmLoading"
      :title="auditType === 1 ? '审核' : '反审核'"
      :width="720"
      @cancel="auditVisible = false"
      @ok="submitAudit"
    >
      <a-checkbox
        :indeterminate="auditIndeterminate"
        :checked="auditCheckAll"
        @change="onCheckAllChangeAudit"
        style="padding-left:10px;padding-bottom:10px;"
      >
        全选
      </a-checkbox>
      <a-checkbox-group v-model="checkedAuditList" @change="onChangeAudit" style="width:100%;">
        <div style="margin-bottom: 20px;" v-for="item in auditData" :key="item.id">
          <p style="margin: 10px 0;font-size: 16px;font-weight:bold">报价编号：{{ item.quota_num }}</p>
          <div class="fee-area">
            <div class="fee-list">
              <div v-for="p in item.pay" :key="p.id">
                <a-checkbox :value="p.id"> {{ p.fee_name }}:{{ p.money }}{{ p.currency_name }} </a-checkbox>
              </div>
            </div>
          </div>
        </div>
      </a-checkbox-group>
    </a-modal>

    <a-modal
      :visible="paymentDetailVisible"
      rowKey="paymentId"
      title="付款单详情"
      :width="1200"
      @cancel="paymentDetailVisible = false"
    >
      <a-table :columns="paymentColumns" :dataSource="paymentDetailData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="action" slot-scope="text, record">
          <a @click="previewDetail(record)">付款单详情</a>
        </template>
      </a-table>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
      @ok="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill :orderData="printData" :type="orderType"> </pay-statement-bill>
      </div>
      <a-button type="primary" id="printStatementsPayBtn" v-print="printStatementsPayBill">确认打印</a-button
      >
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { filterOption } from '@/utils/util'
import { getCommonOptions, getCustomer, getSupplier, downloadExcel } from '@/api/common'
import {
  getItmsPayFinancePage,
  ItmsFinanceFunc,
  exportItmsFinance,
  createItmsPayment,
  getPaymentDetailByOrderId,
  getItmsPayBillFunc
} from '@/api/itms'
import debounce from 'lodash/debounce'
import payBill from '@/views/components/PayBill'
import payStatementBill from '@/views/components/PayStatementBill'
export default {
  components: {
    STable,
    payBill,
    payStatementBill
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {},
      showTotal: '选择条数:0',
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'serial_num'
        },
        {
          title: '报价编号',
          dataIndex: 'quota_num'
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name'
        },
        {
          title: '合约号',
          dataIndex: 'deal_num'
        },
        {
          title: '报关单号',
          dataIndex: 'custom_num'
        },
        {
          title: '费用',
          dataIndex: 'pay_fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '合计',
          dataIndex: 'pay_total'
        },
        {
          title: '品名',
          dataIndex: 'name'
        },
        {
          title: '应收备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      feeOps: [],
      payTargetOps: [],
      paymentVisible: false,
      paymentConfirmLoading: false,
      paymentPayTarget: [],
      checkedPayTarget: null,
      payData: [],
      paymentData: [],
      indeterminate: false,
      checkAll: true,
      checkedPaymentList: [],
      loadData: parameter => {
        return getItmsPayFinancePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      auditVisible: false,
      auditConfirmLoading: false,
      auditType: 1,
      auditData: [],
      checkedAuditList: [],
      auditIndeterminate: false,
      auditCheckAll: true,
      paymentDetailVisible: false,
      paymentColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单编号',
          dataIndex: 'serialNum'
        },
        {
          title: '报价编号',
          dataIndex: 'quotaNum'
        },
        {
          title: '接单日期',
          dataIndex: 'receivingDate'
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '付款对象',
          dataIndex: 'customer'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      paymentDetailData: [],
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '国贸部'
    }
  },
  created() {
    getCommonOptions('fee', { department: 2, type: 2 }).then(v => {
      this.feeOps = v
    })
    getSupplier({ department: 2 }).then(res => {
      this.payTargetOps = res
    })
  },
  watch: {
    selectedRows: function() {
      this.showTotal = `选择条数:${this.selectedRows.length}`
    }
  },
  methods: {
    filterOption,
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      // this.selectedRows = selectedRows
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 2,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.queryParam['auth_agency'] = value.key
      this.customerOps = []
      this.fetching = false
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_id === record.order_id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        for (const item of changeRows) {
          this.selectedRows.push(item)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.order_id === this.selectedRows[index].order_id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    createPayment() {
      const payData = []
      for (const item of this.selectedRows) {
        if (item.pay_fees) {
          for (const fee of item.pay_fees) {
            if (fee.fee_status === 2 && fee.is_payment === 1) {
              fee.quota_num = item.quota_num
              payData.push(fee)
            }
          }
        }
      }
      this.payData = payData
      const data = []
      for (const item of payData) {
        let exist = false
        for (const payTarget of data) {
          if (payTarget.id === item.pay_target_id) {
            exist = true
          }
        }
        if (!exist) {
          data.push({
            id: item.pay_target_id,
            name: item.pay_target_name
          })
        }
      }
      this.paymentPayTarget = data
      let paymentData = []
      if (data.length > 0) {
        this.checkedPayTarget = data[0].id
        paymentData = payData.filter(fee => fee.pay_target_id === data[0].id)
      }
      this.checkedPaymentList = paymentData.map(item => item.id)
      this.paymentData = paymentData
      if (paymentData.length > 0) {
        this.paymentVisible = true
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '没有已审核未加入付款单的费用'
        })
      }
    },
    changePayTarget(e) {
      this.paymentData = this.payData.filter(fee => fee.pay_target_id === this.checkedPayTarget)
      this.checkedPaymentList = this.paymentData.map(item => item.id)
    },
    paymentSubmit() {
      this.paymentConfirmLoading = true
      createItmsPayment({
        fee_ids: this.checkedPaymentList
      })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '创建付款单成功'
            })
            this.paymentVisible = false
            this.$refs.table.refresh(false)
          }
        })
        .finally(_ => {
          this.paymentConfirmLoading = false
        })
    },
    auditOpen(type) {
      if (type === 'basic_audit') {
        this.auditType = 1
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            quota_num: item.quota_num,
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 1) : []
          }
        })
        this.auditData = data
        const pay = data
          .map(item => item.pay)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        let payIdList = []
        for (const item of pay) {
          payIdList = payIdList.concat(item)
        }
        this.checkedAuditList = payIdList
        if (this.checkedAuditList.length === 0) {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以审核的费用'
          })
        } else {
          this.auditVisible = true
        }
      } else {
        this.auditType = 2
        const data = this.selectedRows.map(item => {
          return {
            id: item.order_id,
            quota_num: item.quota_num,
            pay: item.pay_fees ? item.pay_fees.filter(item => item.fee_status === 2 && item.is_payment === 1) : []
          }
        })
        this.auditData = data
        const pay = data
          .map(item => item.pay)
          .map(item => {
            const idList = []
            for (const e of item) {
              idList.push(e.id)
            }
            return idList
          })
        let payIdList = []
        for (const item of pay) {
          payIdList = payIdList.concat(item)
        }
        this.checkedAuditList = payIdList
        if (this.checkedAuditList.length === 0) {
          this.$notification['warn']({
            message: '提示',
            description: '没有可以反审核的费用'
          })
        } else {
          this.auditVisible = true
        }
      }
    },
    submitAudit() {
      this.auditConfirmLoading = true
      const applyName = this.auditType === 1 ? 'basic_audit' : 'undo_audit'
      ItmsFinanceFunc(applyName, {
        ids: this.checkedAuditList
      })
        .then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.selectedRows = []
          this.selectedRowKeys = []
          this.$refs.table.clearSelected()
          this.$refs.table.refresh(false)
          this.auditVisible = false
        })
        .finally(res => {
          this.auditConfirmLoading = false
        })
    },
    paymentDetail(record) {
      getPaymentDetailByOrderId(record.order_id).then(res => {
        this.paymentDetailData = res
        this.paymentDetailVisible = true
      })
    },
    previewDetail(record) {
      getItmsPayBillFunc('payFeeBill', { id: record.paymentId }).then(v => {
        this.printData = v
        this.printPayStatementsBillModel = true
      })
    },
    handleExport() {
      exportItmsFinance('export_pay_data', this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    feeStatusColor(value) {
      if (value.fee_status === 1) {
        return '#faad14'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '#1890ff'
        } else {
          return '#4cd964'
        }
      }
    },
    feeStatusName(value) {
      if (value.fee_status === 1) {
        return '待审核'
      } else {
        if (value.fee_status === 2 && value.is_payment === 1) {
          return '已审核'
        } else {
          return '添加到付款单'
        }
      }
    },
    onChangePayment(checkedValues) {
      this.indeterminate = !!this.checkedPaymentList.length && this.checkedPaymentList.length < this.paymentData.length
      this.checkAll = this.checkedPaymentList.length === this.paymentData.length
    },
    onCheckAllChangePayment(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedPaymentList = []
      if (e.target.checked) {
        this.checkedPaymentList = this.paymentData.map(item => item.id)
      }
    },
    onChangeAudit(checkedValues) {
      const pay = this.auditData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      this.auditIndeterminate = !!this.checkedAuditList.length && this.checkedAuditList.length < payIdList.length
      this.auditCheckAll = this.checkedAuditList.length === payIdList.length
    },
    onCheckAllChangeAudit(e) {
      this.auditCheckAll = e.target.checked
      this.auditIndeterminate = false
      this.checkedAuditList = []
      const pay = this.auditData
        .map(item => item.pay)
        .map(item => {
          const idList = []
          for (const e of item) {
            idList.push(e.id)
          }
          return idList
        })
      let payIdList = []
      for (const item of pay) {
        payIdList = payIdList.concat(item)
      }
      if (e.target.checked) {
        this.checkedAuditList = this.checkedAuditList.concat(payIdList)
      }
    }
  }
}
</script>

<style>
.row-footer .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: none;
}
.selectContextOpen {
  margin: 0;
}
.selectContextClose {
  margin: 0;
  height: 320px;
  overflow: auto;
}
</style>
